export const API_BASE_URL = process.env.REACT_APP_BASE_URL;

export const LOGIN_API = `${API_BASE_URL}/login`;
export const REFRESH_TOKEN_API = `${API_BASE_URL}/token/refresh`;
export const CALL_LIST_API = `${API_BASE_URL}/call`;

export const UPLOAD_API = `${CALL_LIST_API}/upload-csv`;
export const UPDATE_FEEDBACK = `/feedback/feedback`;
export const FETCH_FEEDBACK = `/feedback/feedback-list`;
export const FETCH_FILTER_DROPDOWN_FIEDLS = `${CALL_LIST_API}/opensearch/dropdown-fields`;
export const GET_CALL_STATUS_BY_IDS = `${CALL_LIST_API}/get-call-status`;
export const GET_CALL_URLS = `${CALL_LIST_API}/get-urls`;
export const REPROCESS_CALL = `${CALL_LIST_API}/call-rerun`;
export const GET_CALL_DETAIL_BY_ID = `${CALL_LIST_API}/call-data`;
export const GET_CALL_VERSION_LIST = `/call-version/call-versions`;
export const UPDATE_CALL_GOLD_STANDARD = `${CALL_LIST_API}/manage-gold-standard-call`;
export const GET_CALL_DETAIL_BY_VERSION = `${CALL_LIST_API}/call-data-by-version`;
export const FETCH_DISPOSITION_DROPDOWN_FIEDLS = `/industry`;
export const DISPOSITION_API = `/disposition`;
export const EDIT_CALL = `/call/update`;
export const EDIT_SERVICE_RATINGS = `/service-rating`;
export const EDIT_AGENT_RATINGS = `/agent-rating`;
export const CLIENT_API = `/client`;
export const GET_CLIENT_CATEGORY = `${CALL_LIST_API}/get-client-category/{id}`;

export const FETCH_PROMPT_CONFIG_LIST = `/prompt-config/prompt-config`;
export const VALIDATE_PROMPT = `/prompt/validate_prompt`;
export const FETCH_PROMPT_DATA = `/prompt`;
export const FETCH_PROMPT_TEST_HISTORY = `/prompt-test/prompt-test-history`;
export const FETCH_PROMPT_TEST_LIST = `/prompt-test/prompt-test-list`;
export const FETCH_PROMPT_TEST_LIST_UPDATES = `/prompt-test/ping-status`;
export const FETCH_CANCEL_TEST = `/prompt-test/cancel-test`;
export const GET_CALL_RATIONALE = `${API_BASE_URL}/call/get-call-rationale/{id}`;

export const FETCH_ANALYTICS = `${API_BASE_URL}/analytics/`;

