import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CryptoJS from 'crypto-js';
import {
  APPLIED_FILTERS,
  AUTH_TOKEN,
  CALL_IDS,
  REFRESH_TOKEN,
  TABLE_PARAMS,
  USER_DATA,
} from 'constants/AppConstant';
import { login } from 'api/auth';

export const initialState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN) || null,
};

const handlePasswordEncrption = (data) => {
  const derived_key = CryptoJS.enc.Base64.parse(
    process.env.REACT_APP_AES_ENCRYPTION_KEY
  );
  const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_IV);
  const encryptionOptions = {
    iv: iv,
    mode: CryptoJS.mode.CBC,
  };
  const encrptedPassword = CryptoJS.AES.encrypt(
    data,
    derived_key,
    encryptionOptions
  ).toString();
  return encrptedPassword;
};

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    const formdata = new FormData();
    formdata.append('email', email);
    formdata.append('password', password);

    try {
      const response = await login(formdata);
      const data = response.data;

      if (data.ok) {
        const token = data.access_token;
        localStorage.setItem(AUTH_TOKEN, data.access_token);
        localStorage.setItem(REFRESH_TOKEN, data.refresh_token);
        localStorage.setItem(USER_DATA, JSON.stringify(data.user_data || {}));

        return token;
      } else {
        return rejectWithValue(data.detail?.replace('Firebase: ', ''));
      }
    } catch (err) {
      return rejectWithValue(err.detail || 'Invalid login credentials');
    }
  }
);

export const signOut = createAsyncThunk('auth/signOut', async () => {
  localStorage.removeItem(USER_DATA);
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(CALL_IDS);
  localStorage.removeItem(TABLE_PARAMS);
  localStorage.removeItem(APPLIED_FILTERS);
  sessionStorage.clear();
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = '/';
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = '';
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = '/';
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = '/';
        state.token = action.payload;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = '/';
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = '/';
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;
